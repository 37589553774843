import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'

import Layout from '../components/Layout'
import NumberBar from '../components/NumberBar'
import SEO from '../components/SEO/SEO'
import { useIntlLabel } from '../intl'
import { HomeQuery } from './__generated__/HomeQuery'
import HomeServiceNewSection from './home/HomeServiceNewSection'
import HomeServicePopularSection from './home/HomeServicePopularSection'
import HomeServiceUnpopularSection from './home/HomeServiceUnpopularSection'

const HomePage = () => {
    const intl = useIntl()
    const getIntlLabel = useIntlLabel()

    const { strapiGlobal } = useStaticQuery<HomeQuery>(
        graphql`
            query HomeQuery {
                strapiGlobal {
                    siteName
                    siteDescription
                    siteDescription_mg
                }
            }
        `,
    )

    return (
        <Layout isHome={true}>
            <SEO
                title={intl.formatMessage({ id: 'site_home_title' })}
                description={getIntlLabel(strapiGlobal, 'siteDescription')}
                pageUrl="/"
            />
            <HomeServiceNewSection />
            <HomeServicePopularSection />
            <HomeServiceUnpopularSection />
            <NumberBar />
        </Layout>
    )
}

export default HomePage
