import { Theme, css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby-plugin-react-intl'
import { ReactNode } from 'react-markdown/lib/react-markdown'

export const NumberBarWrapper = styled.div((props) => ({
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    backgroundColor: props.theme.color.semantic.info[100],
    height: props.theme.spacing(15),
    margin: `${props.theme.spacing(1)} 0`,
    borderRadius: props.theme.spacing(4),
    padding: `0 ${props.theme.spacing(5)}`,
    marginBottom: props.theme.spacing(4),

    [props.theme.breakpoints.down(props.theme.breakpoints.lg)]: {
        padding: 8,
    },

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        padding: 0,
    },

    [props.theme.breakpoints.down(props.theme.breakpoints.sm)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        height: 'auto',
        padding: 0,
    },
}))

export const NumberBarItemSeparator = styled.div((props) => ({
    height: props.theme.spacing(3),
    width: '1px',
    backgroundColor: props.theme.color.semantic.info[400],

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        display: 'none',
    },

    [props.theme.breakpoints.down(props.theme.breakpoints.sm)]: {
        display: 'none',
    },
}))

const NumberBarItemBase = (isLink: boolean, theme: Theme) =>
    css({
        display: 'inline-flex',
        //background: theme.color.semantic.info[400],
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(2),
        borderRadius: theme.spacing(2),
        textDecoration: 'none',
        color: theme.color.semantic.info[500],
        cursor: isLink ? 'pointer' : 'default',
        transition: 'all ease 500ms',

        '&:hover': {
            color: isLink
                ? `${theme.color.semantic.info[800]}`
                : `${theme.color.semantic.info[500]}`,

            span: {
                background: isLink
                    ? `${theme.color.semantic.info[800]}`
                    : `${theme.color.semantic.info[500]}`,
            },
        },
    })

export const NumberBarItem = styled(Link)((props) => ({
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: props.theme.spacing(2),
    borderRadius: props.theme.spacing(2),
    textDecoration: 'none',
    color: props.theme.color.semantic.info[500],
    cursor: 'pointer',
    transition: 'all ease 500ms',
    lineHeight: 1.2,

    '&:hover': {
        color: props.theme.color.semantic.info[800],

        span: {
            background: props.theme.color.semantic.info[800],
        },
    },

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        fontSize: 14,
        lineHeight: 1.2,
    },

    [props.theme.breakpoints.down(props.theme.breakpoints.sm)]: {
        width: '100%',
        justifyContent: 'flex-start',
    },
}))

interface INumberBarItem {
    children: ReactNode
    to?: string
}

export const NumberBarItemNumber = styled.span((props) => ({
    alignItems: 'center',
    justifyContent: 'center',
    display: 'inline-flex',
    background: props.theme.color.semantic.info[500],
    minWidth: props.theme.spacing(5),
    height: props.theme.spacing(5),
    color: props.theme.color.neutral.salt[100],
    borderRadius: props.theme.spacing(2),
    marginRight: props.theme.spacing(1),
    fontWeight: 'bold',
    transition: 'all ease 500ms',
}))
