import { graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import { FC } from 'react'

import Container from '../Container'
import { NumberBox } from './__generated__/NumberBox'
import {
    NumberBarItem,
    NumberBarItemNumber,
    NumberBarItemSeparator,
    NumberBarWrapper,
} from './styles'

const NumberBar: FC = () => {
    const intl = useIntl()

    const { allStrapiServiceCategory, allStrapiService } = useStaticQuery<NumberBox>(
        graphql`
            query NumberBox {
                allStrapiServiceCategory {
                    totalCount
                }

                allStrapiService {
                    totalCount
                    edges {
                        node {
                            application {
                                applicationServeiceName
                                serviceApplicationUrl
                            }
                        }
                    }
                }
            }
        `,
    )

    const getDigitalService = () => {
        return allStrapiService.edges.filter((service) => service.node.application != null)
    }

    return (
        <Container>
            <NumberBarWrapper>
                <NumberBarItem to={`/services?type=category`}>
                    <NumberBarItemNumber>{allStrapiServiceCategory.totalCount}</NumberBarItemNumber>
                    {intl.formatMessage({ id: 'number_bar_category' })}
                </NumberBarItem>

                <NumberBarItemSeparator />

                <NumberBarItem to={`/services?type=serviceAll`}>
                    <NumberBarItemNumber>{allStrapiService.totalCount}</NumberBarItemNumber>
                    {intl.formatMessage({ id: 'number_bar_services' })}
                </NumberBarItem>

                <NumberBarItemSeparator />

                <NumberBarItem to={`/services?type=serviceApplication`}>
                    <NumberBarItemNumber>{getDigitalService().length}</NumberBarItemNumber>{' '}
                    {intl.formatMessage({ id: 'number_bar_online_services' })}
                </NumberBarItem>
{/*
                <NumberBarItemSeparator />

                <NumberBarItem to={``}>
                    <NumberBarItemNumber>{getDigitalService().length}</NumberBarItemNumber>{' '}
                    {intl.formatMessage({ id: 'number_bar_service_provider' })}
                </NumberBarItem>
    */}
            </NumberBarWrapper>
        </Container>
    )
}

export default NumberBar
